<template><div><h1 id="installing-linux" tabindex="-1"><a class="header-anchor" href="#installing-linux"><span>Installing Linux</span></a></h1>
<p>Thanks to recent advancements in the chrultrabook community, Linux works really well on most Chromebooks.</p>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>Only Linux kernel 6.4 or newer is supported.</p>
</div>
<h2 id="recommended-distributions" tabindex="-1"><a class="header-anchor" href="#recommended-distributions"><span>Recommended Distributions</span></a></h2>
<div class="custom-container warning"><p class="custom-container-title">WARNING</p>
<p>Ubuntu and Ubuntu-based distributions <strong>may have issues</strong> and are <strong>not supported</strong>.</p>
</div>
<div class="custom-container warning"><p class="custom-container-title">WARNING</p>
<p>Debian versions older than Debian 12 (Bookworm) are <strong>not supported</strong>. Debian 12 (Bookworm) requires a custom kernel, the <a href="#fixing-audio">audio script</a> will automatically install it for you.</p>
</div>
<p><strong>Recommended distros as of October 2023 (in no particular order) are:</strong></p>
<ul>
<li>Arch Linux or EndeavourOS</li>
<li>Fedora or Ultramarine Linux</li>
<li>openSUSE Tumbleweed</li>
<li>Debian 12 (Bookworm)</li>
<li>Pop!_OS</li>
</ul>
<h2 id="installation" tabindex="-1"><a class="header-anchor" href="#installation"><span>Installation</span></a></h2>
<ol>
<li>Create a bootable Linux USB. See <RouteLink to="/docs/installing/bootableusb.html">here</RouteLink> for a guide.</li>
<li>Plug the USB Drive into the Chromebook.</li>
<li>Turn on the Chromebook, press ESC at the POST screen (when the coreboot logo appears), and select your USB to boot from.</li>
<li>Install as you would on any other computer.</li>
</ol>
<div class="custom-container tip"><p class="custom-container-title">NOTE</p>
<p>If you are using RW_LEGACY firmware, if after pressing Ctrl+L you reach the &quot;Additional Firmware Menu&quot; screen, you will need to press 2 to boot from a USB.</p>
<p>See <RouteLink to="/docs/firmware/flashing-firmware.html">this page</RouteLink> for more information about RW_LEGACY booting.</p>
</div>
<h2 id="fixing-audio" tabindex="-1"><a class="header-anchor" href="#fixing-audio"><span>Fixing Audio</span></a></h2>
<p>You will likely have audio issues after installing Linux. Run the following script to fix them.</p>
<h3 id="prerequisites" tabindex="-1"><a class="header-anchor" href="#prerequisites"><span>Prerequisites</span></a></h3>
<ul>
<li>Python 3.10 or newer</li>
<li>Git</li>
</ul>
<div class="custom-container danger"><p class="custom-container-title">DANGER</p>
<p>Using AVS on a device with max98357a will blow your speakers. You have been warned.</p>
</div>
<h4 id="how-to-run-the-script" tabindex="-1"><a class="header-anchor" href="#how-to-run-the-script"><span>How to run the script</span></a></h4>
<ol>
<li>Open a terminal.</li>
<li><code v-pre>git clone https://github.com/WeirdTreeThing/chromebook-linux-audio.git</code></li>
<li><code v-pre>cd chromebook-linux-audio</code></li>
<li><code v-pre>./setup-audio</code></li>
</ol>
<p>For more information please see <a href="https://github.com/WeirdTreeThing/chromebook-linux-audio" target="_blank" rel="noopener noreferrer">WeirdTreeThing's repo</a></p>
</div></template>


